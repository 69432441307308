<template>
    <div class="self-login-wrap">
        <div id="login_box">
            <a-spin :spinning="spinning">

                <a-form>
                    <a-tabs default-active-key="1" @change="changeTabs" :animated="false">
                        <a-tab-pane key="1" tab="密码登录">

                            <a-form-item>
                                <a-input v-model="passwordForm.account" size="large" placeholder="请输入您的账号">
                                    <a-icon slot="prefix" type="user"/>
                                </a-input>
                            </a-form-item>

                            <a-form-item>
                                <a-input type="password" v-model="passwordForm.password" size="large"
                                         placeholder="请输入您的密码">
                                    <a-icon slot="prefix" type="lock"/>
                                </a-input>
                            </a-form-item>

                            <a-form-item>
                                <a-input v-model="passwordForm.security_code" size="large" placeholder="安全码">
                                    <a-icon slot="prefix" type="safety"/>
                                </a-input>
                            </a-form-item>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="短信登录" force-render>
                            <a-form-item>
                                <a-input v-model="verifyCodeForm.mobile" size="large" placeholder="手机号">
                                    <a-icon slot="prefix" type="user"/>
                                </a-input>
                            </a-form-item>
                            <a-form-item>

                                <a-row :gutter="8">
                                    <a-col :span="14">
                                        <a-input v-model="verifyCodeForm.verifyCode" size="large" placeholder="短信验证码">
                                            <a-icon slot="prefix" type="user"/>
                                        </a-input>
                                    </a-col>
                                    <a-col :span="7">
                                        <a-button size="large" @click="getVerifyCode">{{getVerifyCodeText}}</a-button>
                                    </a-col>
                                </a-row>
                            </a-form-item>

                            <a-form-item>
                                <a-input v-model="verifyCodeForm.security_code" size="large" placeholder="安全码">
                                    <a-icon slot="prefix" type="safety"/>
                                </a-input>
                            </a-form-item>

                        </a-tab-pane>
                    </a-tabs>

                    <a-form-item>
                        <a-button type="primary" shape="round" size="large" block @click="login">登录</a-button>
                    </a-form-item>
                </a-form>
                <div class="other">
                    <p> 登录即代表您同意 <a
                            target="_blank" href="#/passport/agreement?type=2">《服务协议》</a><a target="_blank"
                                                                                            href="#/passport/agreement?type=1">《隐私政策》</a>
                    </p>
                    <a class="forgetPwd"
                       href="/passport/retrieve-password">忘记密码</a>
                </div>
            </a-spin>
        </div>
    </div>
</template>

<script>
    import service from "../../../utils/request";
    import {message} from "ant-design-vue";

    export default {
        name: "login",
        data() {
            return {
                spinning: false,
                loginType: 1,
                passwordForm: {
                    account: '',
                    password: '',
                    security_code: ''
                },
                verifyCodeForm:{
                    mobile: '',
                    verifyCode:'',
                    security_code: ''
                },
                getVerifyCodeText:'获取验证码',
                countDown: 60,
                verify_code:'',
            }
        },
        created() {
            let token = localStorage.getItem('token');
            if (token) {
                this.$router.push('/');
            }
        },
        methods: {
            changeTabs(e) {
                this.loginType = e;
            },
            login() {
                if (this.loginType == 1) {
                    this.passwordLogin();
                } else if (this.loginType == 2){
                    this.verifyCodeLogin();
                }
            },
            passwordLogin() {
                this.spinging = true;
                service.post(service.uri.passport.login, this.passwordForm).then((res) => {
                    this.spinging = false;
                    if (res.code == 200) {
                        console.log('res.data:', res.data);
                        console.log('res.data.token:', res.data.token);
                        localStorage.setItem('token', res.data.token);
                        localStorage.setItem('userInfo', JSON.stringify(res.data))
                        this.$router.push('/')
                    }
                });
            },
            getVerifyCode(){
                if(this.countDown < 60) return false;
                if(!this.verifyCodeForm.mobile){
                    message.error("请输入手机号");
                    return false;
                }
                service.post(service.uri.passport.getLoginVerifyCode, {phone: this.verifyCodeForm.mobile, type: 10}).then(() => {

                    this.timer = setInterval(() => {
                        this.countDown--;
                        if(this.countDown <= 0){
                            this.getVerifyCodeText = '获取验证码';
                            this.countDown = 60;
                            clearInterval(this.timer);
                            return ;
                        }
                        this.getVerifyCodeText = '请等待'+this.countDown+'S';
                    }, 1000);
                });
            },
            verifyCodeLogin(){
                this.spinging = true;
                service.post(service.uri.passport.verifyCodeLogin, this.verifyCodeForm).then((res) => {
                    this.spinging = false;
                    if (res.code == 200) {
                        console.log('res.data:', res.data);
                        console.log('res.data.token:', res.data.token);
                        localStorage.setItem('token', res.data.token);
                        localStorage.setItem('userInfo', JSON.stringify(res.data))
                        this.$router.push('/')
                    }
                });
            }
        }
    }
</script>

<style lang="less" scoped>

    .self-login-wrap {
        max-width: 1200px;
        margin: 0 auto;
        height: 600px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    #login_box {
        display: block;
        width: 350px;
        padding: 38px 30px;
        background: #fff;
        border-radius: 10px;
        box-shadow: #f8f8f8;
    }

    .forgetPwd {
        float: right;
    }

</style>